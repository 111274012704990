import "@/styles/globals.css";
import "../styles/globals.css";
import type { AppProps } from "next/app";
import { AuthProvider, useAuth } from "../auth";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { NextPage } from "next";
import { Provider } from "react-redux";
import store from "../redux/store";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  captureException,
  devPrint,
  getCurrentPath,
  isLocalhost,
} from "@/utils/devUtils";
import { useRouter } from "next/router";
import { loadEnTranslations, loadTranslations } from "@/utils/translation";
import { getAppleAccessToken } from "@/utils/appleLogin";
import { getStringFromList } from "@/utils/NetworkUtils";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getGoogleClientId } from "@/utils/projectKeys";
import { initMixpanel, trackEvent } from "@/utils/analyticsFunctions";
import { t } from "@/utils/translation";

// translation function
export const _ = t;

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

interface IAuthAppProps {
  children: ReactNode;
}
let _isLoggingInWithApple = false;
let lastTrackedPath = "";
//ca1b054339edb47d79d71f6959f8a450a.0.sruvr.iSKKZqThGN2L0_eorHMqgw
export const AuthApp = (props: IAuthAppProps) => {
  const { loading, isAuthenticated, loginWithApple } = useAuth();
  const router = useRouter();
  const [loadingTrans, setLoadingTrans] = useState(true);

  // if not use popup but redirect in apple login
  // then we redirect to /?code=<code>
  // could not redirect to /auth/callback?code=<code> as it would give invalid url
  const handleAppleLogin = (): boolean => {
    if (_isLoggingInWithApple) {
      return false;
    }
    _isLoggingInWithApple = true;
    let { code, state } = router.query;
    // code = "ca1b054339edb47d79d71f6959f8a450a.0.sruvr.iSKKZqThGN2L0_eorHMqgw";
    if (code) {
      if (isLocalhost()) {
        toast("Cannot login with apple on localhost");
        return false;
      }

      getAppleAccessToken(getStringFromList(code), "")
        .then((tokenResponse) => {
          loginWithApple(tokenResponse?.accessToken, tokenResponse?.idToken);
        })
        .catch((e) => {
          captureException(e, true);
          if (!isAuthenticated && !loading) {
            router.push("/login");
          }
        });
      return true;
    }
    return false;
  };

  useEffect(() => {
    initMixpanel();
    setLoadingTrans(true);
    loadEnTranslations().then((v) => {
      if (v !== "success") {
        return;
      }
      devPrint("THENNN", v);
      setLoadingTrans(false);
      devPrint();
      loadTranslations();
    });

    const isLoggingIn = handleAppleLogin();
    _isLoggingInWithApple = isLoggingIn;
    // not sure how to handle this...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const path = getCurrentPath();
    // maybe a better way to do this...
    if (path !== lastTrackedPath) {
      lastTrackedPath = path;
      trackEvent(path);
    }
  });
  if (loading || loadingTrans) {
    return <div></div>;
  }

  return <>{props.children}</>;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <GoogleOAuthProvider clientId={getGoogleClientId()}>
      <Provider store={store}>
        <AuthProvider>
          <AuthApp>
            {getLayout(
              <>
                <Component {...pageProps} />
                <ToastContainer />
              </>
            )}
          </AuthApp>
        </AuthProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}
